@use "libs/styles/src/lib/variables";
@use "~rfs/scss" as rfs;

.explore-ecosystem-section {
  @include variables.max-width;
  @include rfs.padding(80px 0);
  position: relative;
  display: flex;
  flex-direction: column;

  & > h3 {
    @include variables.title(2);
    width: fit-content;
    margin: 0;
    color: variables.$color-dark-light;
  }

  & > span {
    @include variables.header(2);
    color: rgba(variables.$color-dark-light, 0.9);
  }

  & > hr {
    @include rfs.margin-top(20px);
    @include rfs.margin-bottom(36px);
    width: 100%;
    height: 1px;
    border: none;
    background-color: variables.$color-light-border;
  }

  &-container {
    @include rfs.padding(56px 100px);
    @include variables.rainbow-gradient-background($flip: true);
    border-bottom: 1px solid variables.$color-light-border;

    @media (max-width: variables.$device-md) {
      @include rfs.padding(24px 64px);
    }
  }
}

.more-button {
  @include rfs.margin-bottom(32px);
  @include rfs.margin-top(32px);
  align-self: center;
}

.ecosystem-org-carousel,
.ecosystem-fund-carousel {
  @include rfs.padding(60px 60px 60px 90px);
  @include variables.side-fade-gradients;
  width: 100%;

  @media (max-width: variables.$device-md) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

.ecosystem-slide {
  height: revert;
}
