@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.endaoment-interface-section {
  @include variables.max-width;
  display: grid;
  grid-template: auto / 18rem minmax(max-content, 0.5fr) 1fr;
  gap: 4rem;
  height: 52rem;

  @media (max-width: 1300px) {
    grid-template: auto / 1fr 2fr 4fr;
    gap: 0.5rem;
  }

  @media (max-width: variables.$device-md) {
    display: block;
  }

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid variables.$color-light-border;
  }
}

.endaoment-interface-text {
  @include rfs.padding-bottom(64px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;

  @media (max-width: variables.$device-md) {
    @include rfs.padding-top(64px);
    height: unset;
  }

  & > div {
    @include rfs.padding(24px);
    @include rfs.margin-bottom(40px);
    width: 120px;
    height: 120px;
    border: 2px solid rgba(variables.$color-org, 0.3);
    border-radius: variables.$border-radius-m;
    box-shadow: variables.$box-shadow-medium-blue;
  }

  h3 {
    @include variables.header(1);
    color: variables.$color-dark-light;
  }

  hr {
    @include rfs.margin(24px 0);
    width: 100%;
    height: 1px;
    border: none;
    background-color: variables.$color-org;
  }

  p {
    @include rfs.margin-bottom(24px);
    @include variables.paragraph;
    color: variables.$color-disabled;
  }
}

.endaoment-interface-desktop-carousel {
  display: flex;
  width: 100%;
  max-height: 52rem;

  & .screenshot {
    img {
      @include variables.screenshot-image;
      object-fit: cover;
    }
  }

  & > div {
    transition-timing-function: linear;
  }
}

.endaoment-interface-mobile-carousel {
  @include variables.side-fade-gradients;
  display: flex;
  max-height: 60rem;
  overflow: hidden;

  & .screenshot {
    max-height: 360px;
    overflow: hidden;

    img {
      @include variables.screenshot-image;
      width: 15rem;
      height: 30rem;
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 15;
      height: 8rem;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0%), rgba(255, 255, 255, 100%) 90%);
      content: "";
      pointer-events: none;
    }
  }
}

.screenshot {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  img {
    height: 100%;
  }
}
