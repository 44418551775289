@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.for-non-profits-section {
  @include variables.max-width;
  @include rfs.padding-top(82px);
  @include rfs.margin-bottom(60px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid variables.$color-light-border;
    border-bottom: 1px solid variables.$color-light-border;
    background: variables.$color-near-white;
  }

  & > div {
    width: 640px;
  }

  hr {
    @include rfs.margin-top(12px);
  }

  @media (max-width: variables.$device-md) {
    padding-right: 0;
    padding-left: 0;
  }

  & > div:first-child {
    @include rfs.padding-bottom(8px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-right: 1px solid variables.$color-lighter;
    background: url("/images/token-bg-left-fade.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: auto 100%;

    @media (max-width: variables.$device-md) {
      @include rfs.margin-left(48px);
      @include rfs.margin-bottom(36px);
    }

    & > *:first-child {
      @include rfs.margin-bottom(24px);
    }

    h3 {
      @include variables.title(3);
      @include rfs.margin-bottom(12px);
      @include rfs.padding(6px);
      max-width: 420px;
    }
  }

  & > div:last-child {
    @include rfs.padding(32px 48px);
    color: variables.$color-disabled;

    & > div {
      @include rfs.padding(16px 0);
      @include rfs.rfs(24px, gap);
      display: flex;
      align-items: center;
      height: 70px;

      & > svg {
        flex-basis: 42px;
        width: 42px;
        height: 42px;
        object-fit: contain;
      }

      & > div {
        border-bottom: 1px solid rgba(variables.$color-purple-gray, 0.5);

        @include rfs.padding-top(16px);
        @include rfs.padding-bottom(16px);
        @include rfs.padding-left(8px);
      }
    }

    h4 {
      @include variables.header(4);
      color: variables.$color-disabled;
    }

    div {
      @include variables.paragraph;
      flex: 1;
    }

    hr {
      @include rfs.margin-left(-10px);
      @include rfs.margin-bottom(12px);
      height: 1px;
      border: none;
      background-color: rgba(variables.$color-purple-gray, 0.5);
    }
  }
}

.footnote {
  @include rfs.margin(32px 28px);
  align-self: center;
  max-width: 400px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: variables.$color-slate;

  a {
    @include variables.text-link;
  }
}

.coming-soon {
  @include variables.coming-soon($condition: "before");

  &::before {
    @include rfs.margin-right(8px);
  }
}
