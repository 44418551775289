@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.three-image-buttons {
  @include variables.button-section-height;
  @include rfs.padding(42px);
  justify-content: center;

  & > div {
    @include variables.max-width;
    gap: 8px;

    & > a {
      gap: 20px;
      width: 374px;
      height: 122px;
      border: 1px solid #e4ebf4;

      @media (max-width: variables.$device-sm) {
        width: unset;
      }
    }
  }

  @media (max-width: variables.$device-sm) {
    max-height: unset;
  }
}

.text-grid-container {
  padding-bottom: 100px;

  @include variables.rainbow-gradient-background($position: "bottom");
}

.separator {
  height: 100%;
  border: 1px solid #e4ebf4;
}
