@use "libs/styles/src/lib/variables";

.avatar {
  $size: 5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: $size;
  min-width: 5rem;
  height: $size;
  border: 1px solid variables.$color-lighter;
  border-radius: variables.$border-radius-xxl;
  overflow: hidden;
  background-color: variables.$color-white;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--square {
    border-radius: variables.$border-radius-m;
  }
}
