@use "libs/styles/src/lib/variables";

.community-fund-card {
  height: 22rem;
  border-radius: variables.$space-l;
  text-align: left;
  color: variables.$color-dark-light;
  background-color: variables.$color-white;

  & > div {
    @include variables.card-hover;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.3rem 1.125rem;
    padding-bottom: 1rem;
    transition: variables.$transition-m;

    & > header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
    }

    &:hover {
      border-color: rgba(variables.$color-fund, 0.35);
      box-shadow: variables.$box-shadow-medium-orange;

      .avatar {
        border-color: rgba(variables.$color-fund, 0.5);
        box-shadow: variables.$box-shadow-medium-orange;
      }
    }
  }

  @media (min-width: variables.$device-md) {
    height: 22rem;
  }
}

.avatar {
  color: variables.$color-fund;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.total {
  position: relative;
  flex-shrink: 0;
  gap: 0;
  padding: 0.5rem;
  text-align: right;
  color: variables.$color-fund;

  &-amount {
    @include variables.header(3);
    margin: 0;
    margin-bottom: 0;
    font-weight: 600;
    color: variables.$color-fund;
  }

  &-label {
    @include variables.paragraph;
    margin: 0;
    color: variables.$color-fund;
    opacity: 0.65;
  }
}

.content {
  &-header {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  &-name {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    margin: 0;
    padding-left: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    color: variables.$color-dark-light;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    @include variables.header(3);
  }

  &-description {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    margin-top: variables.$space-s;
    margin-bottom: 1rem;
    padding: variables.$card-content-padding;
    overflow: hidden;
    text-overflow: ellipsis;
    color: variables.$color-gray;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    @include variables.paragraph;
  }
}

.progress {
  position: relative;
  display: inline-block;
  width: calc(100% - 0.5rem);
  margin-top: auto;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  color: variables.$color-fund;
}

.footer {
  @include variables.card-footer;
}
