@use "sass:color";
@use "libs/styles/src/lib/variables";
@use "~rfs/scss" as rfs;

.hero {
  position: relative;
  display: flex;
  gap: 3vw;
  height: calc(max(100vh, 800px) - #{variables.$NAVBAR_HEIGHT} - #{variables.$LINK_SECTION_HEIGHT});
  min-height: 40rem;
  max-height: 45rem;
  border-bottom: 1px solid variables.$color-lighter;
  padding-bottom: 0;
  overflow: hidden;
  background: radial-gradient(23.78% 73.32% at 67.36% 100%, #fff5f7 39.91%, rgba(255, 249, 249, 0%) 100%),
    radial-gradient(100% 100% at 0% 100%, #e7f6ff 0%, rgba(249, 253, 255, 0%) 100%),
    radial-gradient(87.23% 87.23% at 89.58% 94.89%, #fff5f1 3.54%, rgba(249, 253, 255, 0%) 100%),
    radial-gradient(
      25.76% 82.75% at 67.05% 100%,
      #fbf8ff 0%,
      rgba(247, 243, 255, 72.9%) 28.66%,
      rgba(249, 253, 255, 0%) 100%
    );
  background-position: bottom center;

  @media (max-width: variables.$device-md) {
    @include rfs.padding(16px);
    min-height: unset;
  }
}

.text-section {
  display: flex;
  flex: 0 1 min(38vw, 660px);
  flex-direction: column;
  justify-content: flex-end;

  @include rfs.padding-bottom(64px);
  @include rfs.padding-left(96px);

  h1 {
    @include variables.title;
    @include rfs.padding-left(8px);
    @include rfs.margin-bottom(36px);
    max-width: 580px;
    color: rgba(variables.$color-dark-light, 0.95);

    @media (max-width: variables.$device-md) {
      @include rfs.margin-bottom(32px);
    }
  }

  .slide-description {
    @include rfs.padding(0 8px);
    @include rfs.margin(24px 0 24px);

    & > div {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      height: 66px;
      color: variables.$color-org;

      @media (max-width: variables.$device-md) {
        @include rfs.margin-bottom(32px);
      }

      p {
        @include variables.paragraph($big: true);
      }

      a,
      a svg {
        transition: variables.$transition-s;
      }

      a:hover {
        p {
          opacity: 0.65;
        }

        svg {
          transform: translateX(4px);
          opacity: 0.65;
        }
      }

      svg {
        position: absolute;
        top: 4px;
        right: 0;
      }

      &.has-arrow {
        p {
          @include rfs.padding-right(32px);
        }
      }

      .slide-date {
        @include variables.paragraph;
        color: variables.$color-purple;
        opacity: 0.5;
      }
    }
  }

  @media (max-width: variables.$device-md) {
    flex: 1;
    padding-bottom: 0;
    padding-left: 0;
  }
}

.stats-section {
  @include rfs.margin-top(32px);
  @include rfs.rfs(28px, top);
  @include rfs.rfs(96px, bottom);
  @include rfs.rfs(96px, right);
  position: absolute;
  z-index: 5;
  max-width: 600px;
  height: fit-content;
  margin-left: auto;
  border: 1px solid rgba(variables.$color-purple, 0.5);
  padding: 0;
  overflow: hidden;

  @media (max-width: variables.$device-md) {
    @include rfs.rfs(16px, left);
    @include rfs.rfs(16px, right);
    @include rfs.rfs(0, top);
  }

  .stats-top {
    @include rfs.padding(32px);
    display: flex;
    gap: 12vw;
    justify-content: space-between;

    h3 {
      @include variables.header(1);
      @include rfs.margin-bottom(3px);
      font-size: 36px;
      font-weight: 600;
      text-shadow: 0 4px 16px rgba(0, 0, 0, 2%);
      color: variables.$color-dark-light;
    }

    span {
      color: variables.$color-purple;
    }

    &-right {
      text-align: end;
    }
  }

  .stats-bottom {
    padding: 8px 32px 24px;

    & > div:last-child {
      display: flex;
      justify-content: space-between;
    }
  }

  p,
  span {
    @include variables.paragraph;
    color: variables.$color-disabled;

    mark {
      color: variables.$color-org;
      background-color: transparent;
    }
  }

  hr {
    height: 1px;
    margin-bottom: 12px;
    border: none;
    background-color: variables.$color-lighter;
  }

  @media (max-width: variables.$device-md) {
    margin-left: 0;
  }
}

.image-section {
  display: flex;
  flex: 1;
  grid-area: image-section;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: visible;

  & > svg {
    width: 100%;
    max-width: 1000px;
    height: auto;
    max-height: min(calc(54vw / 2), 500px);
    opacity: 0.5;
  }

  &__container {
    @include rfs.margin-right(72px);
    @include variables.screenshot-image;
    position: relative;
    display: inline-flex;
    justify-self: center;
    width: 100%;
    max-width: 1000px;
    height: min(calc(60vw / 2), 600px);
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    overflow: visible;
    box-shadow: variables.$screenshot-shadow-filter;
    transform: perspective(100em) rotateY(-10deg) translateY(2em);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  @media (max-width: variables.$device-md) {
    display: none;
  }
}

.progress-bar {
  height: 2px;
  background-color: color.adjust(variables.$color-org, $lightness: 27%);

  & > div {
    height: 2px;
    background-color: variables.$color-purple;
    opacity: 0.5;
  }
}

.rounded-progress-bar {
  height: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: variables.$border-radius-xxl;
  background-color: color.adjust(variables.$color-org, $lightness: 30%);

  & > div {
    height: 8px;
    border-radius: variables.$border-radius-xxl;
    background-color: variables.$color-org;
  }
}

.carousel-item-circle,
.decrement-carousel,
.increment-carousel {
  @include rfs.margin(8px);
  color: variables.$color-purple;
  cursor: pointer;

  &.disabled {
    opacity: 0.4;
  }
}
