@use 'libs/styles/src/lib/variables';

.progress-bar {
  &__progress {
    position: relative;
    min-height: 8px;
    border-radius: 4px;
    overflow: hidden;

    &-inner,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      height: 8px;
      border-radius: 4px;
      background-color: currentColor;
    }

    &::after {
      width: 100%;
      opacity: 0.1;
      content: ' ';
    }

    &-inner {
      opacity: 0.6;
      content: ' ';
    }
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.35rem;
    padding: 0 0.25rem;
    @include variables.label();

    span,
    em {
      font-weight: 300;
    }

    em {
      font-weight: 500;
      font-style: normal;
      color: variables.$color-fund;
    }
  }
}
