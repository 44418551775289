@use "libs/styles/src/lib/variables";

.org-card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 24rem;
  border-radius: variables.$space-l;
  padding: 0;
  text-align: left;
  background-color: variables.$color-white;

  @include variables.card-hover;

  & > [class*="Card"] {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    overflow: hidden;
    transition: variables.$transition-m;
  }

  &:hover {
    box-shadow: none;

    & > [class*="Card"] {
      border-color: rgba(variables.$color-org, 0.5);
      box-shadow: variables.$box-shadow-medium-blue;
    }

    .avatar {
      border-color: rgba(variables.$color-org, 0.65);
      box-shadow: variables.$box-shadow-medium-blue;
    }
  }
}

.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9.375rem;
  border-bottom: 1px solid variables.$color-lighter;
  transition: variables.$transition-m;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1.3rem 1.125rem;
  background-color: variables.$color-white;
}

.avatar {
  $height: 5rem;
  $padding: 0.35rem;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: $height;
  max-width: 60%;
  height: $height;
  margin-top: -2.75rem;
  border: 1px solid variables.$color-lighter;
  border-radius: 0.75rem;
  padding: $padding;
  overflow: hidden;
  background: variables.$color-white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 2%);

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    border-radius: 0.5rem;
    overflow: hidden;
    background: transparent;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  span > img,
  img {
    width: auto;
    min-width: calc($height - 2 * $padding);
    max-width: 14.25rem;
    max-height: calc($height - 2 * $padding);
  }
}

.header {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  width: 100%;
  margin-top: 1.125rem;
  margin-bottom: 0.65rem;
  padding: variables.$card-content-padding;
}

.total {
  position: relative;
  flex-shrink: 0;
  gap: 0;
  padding-bottom: 0.1rem;
  padding-left: 1.5rem;
  text-align: right;
  color: variables.$color-org;

  &-amount {
    @include variables.header(3);
    position: absolute;
    right: 0;
    bottom: 1.25rem;
    margin: 0;
    margin-bottom: 0.05rem;
    font-weight: 600;
    color: variables.$color-org;
  }

  &-label {
    @include variables.label;
    color: variables.$color-org;
    opacity: 0.75;
  }
}

.name {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  flex-grow: 1;
  margin: 0;
  overflow: hidden;

  @include variables.header(3);
  text-overflow: ellipsis;
  color: variables.$color-dark-light;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.description {
  @include variables.paragraph;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  margin: 0 0 0.75rem;
  padding: variables.$card-content-padding;
  overflow: hidden;
  text-overflow: ellipsis;
  color: variables.$color-gray;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.granted {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-end;

  span {
    @include variables.label;

    &:first-child {
      color: variables.$color-light;
    }
  }
}

.pills {
  margin-top: auto;
}

.pill {
  &--grants {
    color: variables.$color-org;
  }

  &--donations {
    color: variables.$color-purple;
  }
}

.footer {
  @include variables.card-footer;
  margin-top: auto;

  em {
    color: variables.$color-org;
  }
}
